import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import { actions } from 'features/users/redux';
import Input from 'components/Input';

import './Information.scss';

const Information = () => {
  const b = block('information');
  const [string, setString] = useState('');
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const isVerify = useSelector(state => state.auth.isVerify);
  const dispatch = useDispatch(); 
  const [phone, setPhone] = useState('phone');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (phone) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [phone]);

  const validatePhone = (e) => {
    setString(e.target.value)
    const re = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
    if (!re.test(String(e.target.value))) {
      setPhone('phone')
      if(!e.target.value) {
        setPhone('phone')
      }
    } else {
      setPhone('')
    }
  };

  const saveData = useCallback(() => {
    if (isValid) dispatch(actions.phoneVerification(string));
  }, [dispatch, string, isValid]);

  return <div className={b()}>
  {isVerify ? 
  <div className={b('verify')}>{locale.verifyInfo}</div> 
  :
  <div className={b('block-info')}>
    <Input
      className={b('input-mail')}
      type="text"
      value={string}
      onChange={e => validatePhone(e)}
      callBack={e => setString(e.currentTarget.value)}
      placeholder={locale.enterPhone}
      name="string"
      id='nptMail'
    />
    <button className={b('button-data')} onClick={saveData} disabled={!isValid}>
      {locale.saveData}
    </button>
    </div>
  }
  </div>
}

export default Information;