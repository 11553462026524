import dayjs from "dayjs";
import { addNotify } from "features/notify";

const actionTypes = {
  ACTION_PROCESSING: "cashierJackpot/ACTION_PROCESSING",
  GET_CASHIER_JACKPOTS: "cashierJackpot/GET_CASHIER_JACKPOTS",
};

const getCashierJackpot = (params) => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { role } = getState().auth;

  // let to = dayjs(params.to)
  //   .add(+1, "day")
  //   .format("YYYY-MM-DD");
  if (role !== 1) return;
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

  const response = await api.cashierJackpot.getCashierJackpot({
    date_from: params.from,
    date_to: params.to,
  });

  if (response.success) {
    dispatch({
      type: actionTypes.GET_CASHIER_JACKPOTS,
      payload: response.data,
    });
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  } else {
    dispatch(addNotify("Error", "error"));
  }
};

export { actionTypes, getCashierJackpot };
