import { errorStatusMessage } from "./errorStatusMessage";

export const locale = {
  ...errorStatusMessage,
  changeLang: "Change language",
  logout: "Logout",
  other: "Other",
  successAuth: "Success authorization",
  notRules: "Insufficient login privileges",

  newPlayer: "New player",
  userName: "Username",
  password: "Password",
  createAccount: "Create account",
  "/user-create": "Cash register - New player",
  "/total": "Cash register - User transactions",
  "/": "Menu",
  "/locale": "Languagies",
  "/user-edit": "Cash register - User edit",
  "/cashier-jackpot": "Cash register - Cashier jackpot",
  "/users-list": "Cash register - User cards balances",
  "/tree": "Tree",
  userCardBalances: "Users cards balances",
  userCard: "User card",
  accountBalance: "Account balance",
  nickname: "Nickname",
  id: "Id",
  balance: "Balance",
  ban: "Ban",
  banSuccess: "Ban success",
  unbanSuccess: "Unban success",
  unban: "Unban",
  resetPassword: "Reset password",
  resetSuccess: "Reset password success",
  networkError: "Network error",
  createSuccess: "Create success",
  topUp: "TopUp",
  withdrawal: "Withdrawal",
  topUpSuccess: "TopUp success",
  withdrawalSuccess: "Withdrawal success",
  topUpWithdrawal: "TopUp/Withdrawal",
  ok: "Ok",
  theNewPassword: "The new password",
  for: "for",

  "/transaction-history": "Partners - Transaction history",
  startDate: "Start date",
  endingDate: "Ending date",
  display: "Display",
  email: "E-mail",
  emailPlace: "Enter your E-mail",
  emailButton: "Send code",
  date: "Date",
  amount: "Amount",

  languageSelection: "Language selection",
  login: "Login",

  cashRegister: "Cash register",
  partners: "Partners",
  createUser: {
    1: "New user",
    2: "New shop",
    3: "New admin"
  },
  balances: {
    1: "User cards balances",
    2: "Shop cards balances",
    3: "Admin cards balances"
  },
  cashiersTransactions: {
    1: "User transactions",
    2: "Cashier transactions",
    3: "Admin transactions"
  },
  transactionHistory: "Transaction history",
  tree: 'Tree',

  total: "Total",
  term: "Term",
  deposit: "Deposit",
  withdraw: "Withdraw",
  cashPayment: "Cash payment",
  cashPayout: "Cash payout",

  accounting: "Accounting",
  "/accounting": "Accounting",
  information: "Information",
  "/information": "Information",

  checkEmail: "A confirmation code has been sent to your email",
  placeCode: "Confirmation code",
  saveData: "Save data",
  successData: "Your data has been successfully saved",

  verifyCheck:
    "Please enter your phone number to facilitate your communication with the bingo216 adminstrastion",
  errorPassword: "Please enter correct mail",
  verifyInfo: "You are already verified!",

  enterPhone: "Please enter your phone number",

  totalDeposit: "Total deposit",
  totalWithdrawal: "Total withdrawal",

  // sessionExpired: 'Session expired!',
  // sessionExpiredText: 'Your session has expired because you have been idle for at least 20 minutes on this page',

  jackpotWinnersList: "Jackpot winners list",
  startAmount: "Start amount",

  winnerID: "Winner ID",
  nickname: "Nickname",
  winDate: "Win date",
  amountOfTheWinningJackpot: "Amount of the winning jackpot",

  cashierJackpot: "Cashier jackpot",
  page: "Page",
  withdrawalAboveLimit: 'Withdrawal above limit',
  depositAboveLimit: 'Deposit above limit',
};
