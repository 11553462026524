import { initialState } from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING:
      return { ...state, actionProcessing: action.payload };
    case actionTypes.GET_TOTAL_SUCCESS:
      return { ...state, actionProcessing: false, totalInfo: action.payload.info, users: action.payload.users }
    case actionTypes.GET_USER_SUCCEEDED:
      return {
        ...state,
        users: state.users.map(t => t.userId === action.payload.id ? { ...t, ...action.payload.data } : t),
        actionProcessing: false,
      }
    default: return { ...state };
  }
}
