import BaseApi from '../BaseApi';
import { TotalConverter } from './TotalConverter';

class TotlApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new TotalConverter();
  }

  getTotal = (fromDate, toDate) => this.sendQuery(
    this.queryTypes.POST,
    `${this.baseUrl}/total`,
    {
      from_date: fromDate,
      to_date: toDate,
    },
    null,
    this.converter.totalConverter,
  );

  getUserData = (id, fromDate, toDate) => this.sendQuery(
    this.queryTypes.POST,
    `${this.baseUrl}/user_total`,
    {
      target_id: id,
      from_date: fromDate,
      to_date: toDate,
    },
    null,
    this.converter.convertUser,
  );

  getFullTotal = (fromDate, toDate) => this.sendQuery(
    this.queryTypes.POST,
    `${this.baseUrl}/full_total`,
    {
      from_date: fromDate,
      to_date: toDate,
    },
    null,
    this.converter.convertFullTotal,
  );
}

export default TotlApi
