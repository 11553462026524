export class TotalConverter {
  totalConverter = (data) => ({
    info: {
      credits: data.credits_sum,
      debits: data.debits_sum,
      total: data.total,
    },
    users: data.descendants.map(t => ({
      userId: t.user_id,
      username: t.username,
    }))
  });
  
  convertUser = data => ({
    credits: data.credits_sum,
    debits: data.debits_sum,
    total: data.total,
  });

  convertFullTotal = data => { 
    const descendants = data.descendants.map(t => ({
      userId: t.user_id,
      username: t.username,
      total: t.total,
    }));
    const userTotal = data.total;
    return {descendants: descendants, userTotal: userTotal}
};
}

