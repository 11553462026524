import { addNotify } from "features/notify";
import dayjs from "dayjs";
import { actions as authActions } from "features/Auth";

const actionTypes = {
  ACTION_PROCESSING: "accounting/ACTION_PROCESSING",
  GET_FULL_TOTAL_SUCCESS: "accounting/GET_TOTAL_SUCCESS",
};

const getTotal = (fromDare, toDate) => async (dispatch, getState, extra) => {
  const { api } = extra;
  dispatch(authActions.notifyAutoLogout())
  dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
  const newToDate = dayjs(toDate)
    .add(+0, "day")
    .format("YYYY-MM-DD");
  const response = await api.total.getFullTotal(fromDare, newToDate);
  if (response.success) {
    dispatch({
      type: actionTypes.GET_FULL_TOTAL_SUCCESS,
      payload: response.data,
    });
  } else {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    dispatch(addNotify("Error", "error"));
  }
};

export { actionTypes, getTotal };