import dayjs from "dayjs";
import { addNotify } from "features/notify";
import { actions as authActions } from "features/Auth";

const actionTypes = {
  ACTION_PROCESSING: "user/ACTION_PROCESSING",
  GET_TRANSACTIONS_SUCCESS: "user/GET_TRANSACTIONS_SUCCESS"
};

function getTransactions(period, page, itemsOnPage) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    dispatch(authActions.notifyAutoLogout());
    const { api } = extra;
    let to = period.endDate;
    to = dayjs(period.endDate)
      .add(+1, "day")
      .format("YYYY-MM-DD");
    const newPeriod = {
      startDate: dayjs(period.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(to).format("YYYY-MM-DD")
    };
    const response = await api.transaction.getTransactions(
      newPeriod,
      page,
      itemsOnPage
    );
    if (response.success) {
      dispatch({
        type: actionTypes.GET_TRANSACTIONS_SUCCESS,
        payload: response.data
      });
    } else {
      dispatch(addNotify("Error", "error"));
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    }
  };
}

export { actionTypes, getTransactions };
