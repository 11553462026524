import React, { useState, useCallback, useEffect, useMemo } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';

import Input from 'components/Input';
import Spinner from 'components/Spinner';
import { actions } from '../redux';
import searchSvg from './img/search.svg';

import './Accounting.scss';

const Accounting = () => {
  const b = block('total');
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  // const totalInfo = useSelector(state => state.total.totalInfo);
  // const name = useSelector(state => state.auth.name, shallowEqual);
  const userList = useSelector(state => state.accounting.users.descendants, shallowEqual);
  const actionProcessing = useSelector(state => state.accounting.actionProcessing, shallowEqual);
  const userId = useSelector(state => state.auth.role, shallowEqual);
  const userName = useSelector(state => state.auth.name, shallowEqual);
  const total = useSelector(state => state.accounting.users.userTotal);
  const [fromDate, changeFromDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [toDate, changeToDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  useEffect(() => {
    dispatch(actions.getTotal(fromDate, toDate));
  }, [])
  const callBack = useCallback(() => dispatch(actions.getTotal(fromDate, toDate)), [fromDate, toDate, dispatch])

  const users = useMemo(() => 
  userList.map(t => (
    <div
      key={t.userId}
      className={b('row')}
    >
      <span className={b('td', { type: 'left' })}>{t.username}</span>
      <span className={b('td')}>{t.total}</span>
    </div>
  )), [b, userList]);
  const user = useMemo(() =>(
  <div
    key={userId}
    className={b('row', { user: true})}
  >
    <span className={b('td', { type: 'left' })}>{userName}</span>
    <span className={b('td')}>{total}</span>
  </div>), [userId, userName, total]);


  return <section className={b()}>
    <div className={b('title')}>{locale.login}</div>
    <div className={b('input-block')}>
      {`${locale.startDate}:`}
      <Input value={fromDate} onChange={e => changeFromDate(e.currentTarget.value)} type="date" />
    </div>
    <div className={b('input-block')}>
      {`${locale.endingDate}:`}
      <Input value={toDate} onChange={e => changeToDate(e.currentTarget.value)} type="date" />
    </div>
    <div className={b('bottom')}>
      <div className={b('button', { isLoading: actionProcessing })} onClick={actionProcessing ? f => f : callBack}>
        {locale.display}
        <SVGInline svg={searchSvg} className={b('ok').toString()} />
      </div>
    </div>
    <article className={b('result')}>
      <div className={b('result-header')}>
        <span className={b('td', { type: 'left' })}>{locale.nickname}</span>
        <span className={b('td')}>{locale.total}</span>
      </div>
      <Spinner isLoading={actionProcessing} />
      {user}
      {users}
    </article>
  </section>
}

export default Accounting;

