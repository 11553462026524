import React from 'react';
import block from 'bem-cn';

import './Spinner.scss';

const Spinner = ({ isLoading }) => {
  const b = block('spinner');
  return isLoading ?
    <div className={b()}>
      <div className={b('loader')} />
    </div>
  : null;
};

export default Spinner;