import {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {actions as authAction} from "../features/Auth";

const useAutoLogout = () => {
	const isAuth = useSelector(state => state.auth.isAuth, shallowEqual),
		dispatch = useDispatch();

	const logout = () => dispatch(authAction.logOut())

	const duration = 1800000

	const dependencies = [
		useSelector(state => state.auth.autoLogoutNotices, shallowEqual),
		useLocation().pathname
	]

	useEffect(() => {
		const interval = isAuth && setInterval(() => logout(), duration)
		return () =>  clearInterval(interval)
	}, [dependencies])
}
export default useAutoLogout

//Eсть 2 зависимости, 1вая отвечает за запросы, за санки, вторая зависимость это сменяемость пути.
//Есть интервал, он идет 30 минут, как только 30 минут проходит, то происходит логаут.
// Интервал находится в useEffect, он реагируют на 2 эти зависимости, если пользователь меняет путь или делает какой
// либо запрос, например смена языка, то useEffect срабатывает, return сбрасывает прошлый interval и создает новый,
// ( тоесть сбрасывает бездействие(эти 30 минут) )