import React, { useMemo, useState } from "react";
import block from "bem-cn";
import SVGInline from "react-svg-inline";
import { Link } from "react-router-dom";

import openSvg from "./img/open.svg";
import awaySvg from "./img/away.svg";
import cont from "./img/continue.svg";
import play from "./img/play.svg";

import "./MenuItem.scss";

const MenuItem = ({ locale, item, role }) => {
  const b = block("menu-item");
  const [isOpen, changeOpen] = useState(false);
  const itemList = useMemo(
    () =>
      item.items
        .filter((t) => (t.roles ? t.roles.indexOf(+role) !== -1 : true))
        .map((temp) => (
          <Link className={b("element")} key={temp.textId} to={temp.route}>
            {typeof locale[temp.textId] === "object"
              ? locale[temp.textId][role]
              : locale[temp.textId]}
            <SVGInline svg={cont} className={b("image").toString()} />
          </Link>
        )),
    []
  );
  return (
    <React.Fragment>
      <div className={b()} onClick={() => changeOpen(!isOpen)}>
        {locale[item.textId]}
        <SVGInline svg={play} className={b("image").toString()} />
      </div>
      {isOpen && <div className={b("element-list")}>{itemList}</div>}
    </React.Fragment>
  );
};

export default MenuItem;
