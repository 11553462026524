import React, { useEffect } from "react";
import block from "bem-cn";
import { Route, Switch } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import useAutoLogout from "./hooks/useAutoLogout";

import { actions as authAction } from "./features/Auth";
import Notify from "features/notify/view";
import CreateUser from "./features/users/view/CreateUser";
import UsersList from "./features/users/view/UsersList";
import UserEdit from "./features/users/view/UserEdit";
import { Total } from "./features/Total";
import { Accounting } from "./features/accounting";
import Information from "./features/information";
import TransactionHistory from "./features/transaction/view/TransactionHistory";
import CashierJackpot from "./features/cashierJackpot";

import Footer from "components/Footer";
import Header from "components/Header";

import { Locale } from "features/locale";
import { Auth } from "features/Auth";
import Subheader from "components/Subheader";
import Menu from "components/Menu";
import { Tree } from "./features/tree";

import "./App.scss";

const App = () => {
  const b = block("app");
  const localeP = useSelector((state) => state.locale.locale, shallowEqual);
  const isAuth = useSelector((state) => state.auth.isAuth, shallowEqual);
  const isVerify = useSelector((state) => state.auth.isVerify, shallowEqual);
  const user = useSelector((state) => state.auth, shallowEqual);
  const role = useSelector((state) => state.auth.role, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authAction.checkAuth());
  }, [dispatch]);

  useAutoLogout();

  return (
    <div className={b()}>
      <Notify />
      {/*<Inactive />*/}
      {isAuth && <Header />}
      {isAuth && <Subheader user={user} getBalance={authAction.getBalance} />}
      {isAuth ? (
        <Switch>
          <Route
            exact
            key="/user-create"
            path="/user-create"
            component={CreateUser}
          />
          <Route exact key="/locale" path="/locale" component={Locale} />
          <Route
            exact
            key="/users-list"
            path="/users-list"
            component={UsersList}
          />
          <Route exact key="/" path="/" component={Menu} />
          <Route exact key="/total" path="/total" component={Total} />
          <Route exact key="/tree" path="/tree" component={Tree} />
          <Route
            exact
            key="/user-edit/:id"
            path="/user-edit/:id"
            component={UserEdit}
          />
          <Route
            exact
            key="/transaction-history"
            path="/transaction-history"
            component={TransactionHistory}
          />
          <Route
            exact
            key="/information"
            path="/information"
            component={Information}
          />
          {(role === 2 || role === 3) && (
            <Route
              exact
              key="/accounting"
              path="/accounting"
              component={Accounting}
            />
          )}
          <Route
            exact
            key="/cashier-jackpot"
            path="/cashier-jackpot"
            component={CashierJackpot}
          />
        </Switch>
      ) : (
        <Auth />
      )}
      <Footer locale={localeP} isAuth={isAuth} isVerify={isVerify} />
    </div>
  );
};

export default App;
