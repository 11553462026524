import React, { useState, useCallback, useMemo, useEffect } from "react";
import block from "bem-cn";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import SVGInline from "react-svg-inline";

import { actions } from "../../redux";

import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";

import Input from "components/Input";
import Paginator from "components/Paginator";
import searchSvg from "../img/search.svg";
import calendarSvg from "../img/calendar.svg";
import dayjs from "dayjs";

import TransactionItem from "./TransactionItem";

import "./TransactionHistory.scss";

const b = block("transaction-history");
const itemsOnPage = 100;

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const [page, changePage] = useState(0);
  const [filterValue, onChangeFilterValue] = useState("");
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { transactions, totalDeposit, totalWithdrawal, count } = useSelector(
    state => state.transaction,
    shallowEqual
  );
  const [startDate, setStartDate] = useState(dayjs(new Date()).$d);
  const [endDate, setEndDate] = useState(dayjs(new Date()).$d);

  const pageCount = useMemo(() => Math.ceil(count / itemsOnPage), [count]);

  const onChangePage = useCallback(newPage => {
    changePage(newPage);
  }, []);

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const onClick = useCallback(() => {
    dispatch(
      actions.getTransactions({ startDate, endDate }, page, itemsOnPage)
    );
  }, [dispatch, endDate, startDate, page]);

  useEffect(() => {
    dispatch(
      actions.getTransactions({ startDate, endDate }, page, itemsOnPage)
    );
  }, [dispatch, page]);

  const list = useMemo(
    () =>
      transactions
        .filter(
          t =>
            ~t.email?.toUpperCase().indexOf(filterValue.toUpperCase()) || false
        )
        .map((item, index) => (
          <TransactionItem key={`${item.email}_${index}`} item={item} />
        )) || [],
    [filterValue, transactions]
  );

  return (
    <div className={b()}>
      <div className={b("filter-block")}>
        <div className={b("filter-item")}>
          <div className={b("filter-title")}>{locale.startDate}</div>
          <div className={b("input")}>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              selectsStart
              className={b("date")}
              startDate={startDate}
              dateFormat="dd.MM.yyyy"
              endDate={endDate}
            />
            <SVGInline svg={calendarSvg} className={b("calendar").toString()} />
          </div>
        </div>
        <div className={b("filter-item")}>
          <div className={b("filter-title")}>{locale.endingDate}</div>
          <div className={b("input")}>
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              className={b("date")}
              endDate={endDate}
              dateFormat="dd.MM.yyyy"
              minDate={startDate}
            />
            <SVGInline svg={calendarSvg} className={b("calendar").toString()} />
          </div>
        </div>
      </div>
      <div className={b("search-button")} onClick={onClick}>
        <span>{locale.display}</span>
        <SVGInline
          svg={searchSvg}
          className={b("search-button-icon").toString()}
        />
      </div>
      <div className={b("search-block")}>
        <SVGInline svg={searchSvg} className={b("search-icon").toString()} />
        <Input
          value={filterValue}
          callBack={changeFilterValue}
          style={{ paddingLeft: "2rem" }}
        />
      </div>
      <div className={b("result-wrapper")}>
        <div className={b("users-header")}>
          <span className={b("user-header-item", { type: "left" })}>
            {locale.email}
          </span>
          <span className={b("user-header-item", { type: "center" })}>
            {locale.date}
          </span>
          <span className={b("user-header-item", { type: "right" })}>
            {locale.amount}
          </span>
          <span className={b("user-header-item", { type: "right" })}>
            {locale.balance}
          </span>
        </div>
        <div className={b("items")}>{list}</div>
        <div className={b("total")}>
          <div className={b("total-item")}>
            <span className={b("total-label")}>{locale.totalDeposit}</span>
            <span className={b("total-value")}>{totalDeposit}</span>
          </div>
          <div className={b("total-item")}>
            <span className={b("total-label")}>{locale.totalWithdrawal}</span>
            <span className={b("total-value")}>{totalWithdrawal}</span>
          </div>
        </div>
        <div className={b("paginator-wrapper")}>
          <Paginator
            count={pageCount}
            currentPage={page}
            onPageClick={onChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
