import React, { useCallback } from 'react';
import block from 'bem-cn';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';

import { actions as authActions } from 'features/Auth';

import settings from './img/settings.svg';
import information from './img/information.svg';
import logout from './img/logout.svg';

import './Footer.scss';

const Footer = ({ locale, lang, changeLang, logOut, isAuth, isVerify }) => {
  const b = block('footer');
  const dispatch = useDispatch();

  const history = useHistory();

  const handleLogout = useCallback(() => {
    dispatch(authActions.logOut({ callback: () => history.push('/') }));
  }, [dispatch, history]);

  return <footer className={b()}>
    <div className={b('title')}>{locale.other}</div>
    <Link to='/locale' className={b('item')}>
      {locale.changeLang}
      <SVGInline svg={settings} className={b('image').toString()} />
    </Link>
    {isAuth && <Link to='/information' className={b('item')}>
      {locale.information}
      <SVGInline svg={information} className={b('image', { isVerify: `${isVerify}`}).toString()} />
    </Link>}
    {isAuth && <div className={b('item')} onClick={handleLogout}>
      {locale.logout}
      <SVGInline svg={logout} className={b('image').toString()} />
    </div>}
  </footer>
}

export default Footer;
