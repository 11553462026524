export const initialState = {
  actionProcessing: false,
  startAmount: {
    // MINI: 1,
    // MINOR: 1,
  },
  lastWinners: [
    // {
    //   name: "MINOR",
    //   id: 7,
    //   deleted: true,
    //   winners: {
    //     winner_name: "iakris@mail3.ssdf",
    //     win_date: "2022-03-25 19:11:05.432330",
    //     win_amount: 1.6,
    //   },
    // },
  ],
};
