export const initialState = {
  isAuth: false,
  name: '',
  balance: 0,
  currency: '',
  role: null,
  isVerify: false,
  id: null,
  isProcess: false,
  autoLogoutNotices: 0
};