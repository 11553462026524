import { initialState } from "./initial";
import { actionTypes } from "./actions";

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING:
      return { ...state, actionProcessing: action.payload };

    case actionTypes.GET_CASHIER_JACKPOTS:
      return { ...state, ...action.payload };

    default:
      return { ...state };
  }
};
