/* eslint-disable no-continue */
import React, { useState, useMemo } from "react";
import block from "bem-cn";
import PropTypes from "prop-types";
import SVGInline from "react-svg-inline";
import { shallowEqual, useSelector } from "react-redux";

import ArrowSVG from "./img/arrow.svg";

import "./Paginator.scss";

const b = block("paginator");

const Paginator = ({ count, currentPage, onPageClick }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const [isOpen, changeOpen] = useState(false);

  // массив страниц с пропущенной активной страницей
  const items = useMemo(() => {
    let result = [];
    for (let i = 0; i < count; i += 1) {
      if (currentPage === i) continue;
      result.push(i);
    }
    return result;
  }, [count, currentPage]);

  const itemsList = useMemo(
    () =>
      items.map(item => (
        <li key={item} className={b("item")} onClick={() => onPageClick(item)}>
          <span className={b("item-value")}>{item + 1}</span>
        </li>
      )),
    [items, onPageClick]
  );

  return (
    <div className={b()}>
      <p className={b("title")}>{`${locale.page}:`}</p>
      <div
        className={b("main", { open: isOpen })}
        onClick={() => changeOpen(!isOpen)}
        onMouseLeave={() => changeOpen(false)}
      >
        <div className={b("item", { current: true })}>
          <span className={b("item-value", { current: true })}>
            {currentPage + 1}
          </span>
          <SVGInline className={b("item-arrow").toString()} svg={ArrowSVG} />
        </div>
        {isOpen && <ul className={b("items")}>{itemsList}</ul>}
      </div>
    </div>
  );
};

Paginator.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,

  onPageClick: PropTypes.func.isRequired
};

export default Paginator;
